import './App.css';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import reviewify_scrn from "./assets/reviewify_scrn.PNG";
import instascrn2 from "./assets/instascrn2.PNG";
import PortfolioItemCard from './PortfolioItemCard';
import soon from "./assets/soon.jpg";


function Work() {
    return (
        <div className="work">
            <div id="work-test">
                <div id="work">

                </div>
            </div>
            <h1>Recent Projects</h1><br></br>
            <div className='work-flex'>
                <PortfolioItemCard imgSrc={soon} title={"Coming Soon..."}
                    tags={['Java', 'Spring Boot', 'MongoDB', 'ReactJs']}
                    gitLink={"/"} ></PortfolioItemCard>
                <PortfolioItemCard imgSrc={reviewify_scrn} title={"Reviewify"}
                    tags={['NodeJs', 'Express', 'ReactJs', 'Postgresql', 'OAuth2.0',
                        'Spotify Web API']} gitLink={"https://github.com/mattchanway/Capstone2-Reviewify-ExpressJs-Postgresql-ReactJs"} ></PortfolioItemCard>
                <PortfolioItemCard imgSrc={instascrn2} title={"Insta-Clone"}
                    tags={['JavaScript', 'Python', 'Flask', 'SQLAlchemy', 'API Creation', 'Postgresql']}
                    gitLink={"https://github.com/mattchanway/Capstone1-Instagram-Clone-Python-Flask-SQLAlchemy-VanillaJs"} ></PortfolioItemCard>

            </div>



        </div>
    );
}

export default Work;