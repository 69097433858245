import './App.css';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import ContactForm from './ContactForm';


function Contact() {
    return (
        <div className="contact">
            <div id="contact-test">
                <div id="contact"></div>
            </div>
            <div className='contact-container'>
                <div className='contact-form-col'>
                    <h2>Send A Message</h2>
                    <ContactForm></ContactForm>
                </div>
                <div className='contact-message-col'>
                    <h2>Get In Touch</h2>
                    <p>I'm looking forward to hearing from you - simply fill out the form and send me an email.</p>
                </div>

            </div>

        </div>
    );
}

export default Contact;