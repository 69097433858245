import './App.css';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import IoIosAddCircleOutlineIcon from "./FontAwesomePlus";


function PortfolioItemCard({ imgSrc, title, tags, gitLink }) {
    return (
        <a href={gitLink} className="portfolio-link">
            <div className="portfolio-item-card">
                <div className='portfolio-item-img-wrapper'>
                    <img src={imgSrc} className='portfolio-item-img'></img>
                </div>
                <div className='portfolio-item-card-main'>
                    <div className='tags-div'>
                        <h3>{title}</h3>
                        <p> {tags.map(tag => `${tag}` + ", ")}</p>
                    </div>
                    <div className='icon-div'>
                        <IoIosAddCircleOutlineIcon size={"50px"}></IoIosAddCircleOutlineIcon>
                    </div>
                </div>
            </div >
        </a>
    );
}

export default PortfolioItemCard;