import './App.css';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { useState } from 'react';
import Button from '@mui/material/Button';
import emailjs from 'emailjs-com';
import Snackbar from '@mui/material/Snackbar';


function ContactForm() {

    const INIT_STATE = { name: "", email: "", subject: "", message: "" }
    const [formData, setFormData] = useState(INIT_STATE);
    const SERVICE_ID = 'service_oiiez3e';
    const TEMPLATE_ID = 'template_dh0f2ji';
    const USER_ID = 'wZz95RyY_sSyuMVhA';
    const [isOpen, setIsOpen] = useState(false);

    function handleChange(evt) {
        const { name, value } = evt.target;

        setFormData(formData => ({ ...formData, [name]: value }))
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsOpen(false);
    }

    function handleSubmit(e) {
        e.preventDefault();
        setIsOpen(true);
        setFormData(INIT_STATE);
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID);
        e.target.reset()



    }



    return (
        <div className="contact-form-div">
            <form className='contact-form' onSubmit={handleSubmit}>
                <input type="text" placeholder='Name' name='name' value={formData.name} onChange={handleChange}></input><br></br>
                <input type="text" placeholder='Email' name='email' value={formData.email} onChange={handleChange}></input><br></br>
                <input type="text" placeholder='Subject' name='subject' value={formData.subject} onChange={handleChange}></input><br></br>
                <textarea rows="4" cols="5" placeholder='Message' name='message' value={formData.message} onChange={handleChange}></textarea><br></br>
                <button className='contact-btn'>Send Message</button>
            </form>
            <Snackbar open={isOpen} autoHideDuration={3000} onClose={handleClose} message="Message Sent!"></Snackbar>


        </div>
    );
}

export default ContactForm;