import './Home.css';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
// import Navbar from './Navbar';



function Test() {
    return (
        <div id="home" className="area" >

            <div className='main-heading-div'>
                <h1>Welcome!</h1>
                <h2>-Matt Chanway, Full-Stack Software Developer</h2>
                <Button id='view-work-btn' variant="contained" href="#work" size="large">View My Work</Button>
            </div>
            <ul className="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div >
    );
}

export default Test;