import './App.css';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Home from "./Home";
import Work from "./Work";
import Test from "./Test";
import About from "./About";
import Contact from './Contact';
import Navbar from './Navbar';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar></Navbar>
        <Test></Test>

        <About></About>
        <Work></Work>
        <Contact></Contact>
      </BrowserRouter>
    </div>
  );
}

export default App;
