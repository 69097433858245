import './Navbar.css';
import { BrowserRouter, Routes, Route, useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ContactForm from './ContactForm';
import { HashLink } from 'react-router-hash-link';


function Navbar() {

    const linkStyle = {
        margin: "0.7rem",
        color: "blue"
    }

    // const [isNavbarTransparent, setIsNavbarTransparent] = useState(true);
    // const [scrollPosition, setScrollPosition] = useState(0);

    // useEffect(() => {
    //     // Define a function that is called when the scroll event fires
    //     function updatePosition() {
    //         console.log(window.scrollY)
    //         setScrollPosition(window.scrollY);
    //         console.log(scrollPosition)
    //         if (scrollPosition < 200) {
    //             setIsNavbarTransparent(true);
    //         } else {
    //             setIsNavbarTransparent(false);
    //         }
    //     };
    //     window.addEventListener('scroll', updatePosition)
    //     updatePosition()
    //     return () => window.removeEventListener('scroll', updatePosition)

    // }, []);


    return (

        <div className="navbar">
            <div className='menu-items'>
                <HashLink smooth to="/#home" style={linkStyle} id="home-hash-link">
                    Home
                </HashLink>
                <HashLink smooth to="/#about" style={linkStyle} id="about-hash-link">
                    About
                </HashLink>
                <HashLink smooth to="/#work" style={linkStyle} id="work-hash-link">
                    Portfolio
                </HashLink>
                <HashLink smooth to="/#contact" style={linkStyle} id="contact-hash-link">
                    Contact
                </HashLink>

            </div>
        </div>
    );
}

export default Navbar;