import './App.css';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';


function Home() {
    return (
        <div id="home" className="home">
            <h1>Thank You For Stopping By</h1>
            <h2>-Matt Chanway, Full-Stack Software Developer</h2>
            {/* <Button variant="contained" href="#work">My Work</Button> */}

        </div>
    );
}

export default Home;