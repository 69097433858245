import './App.css';
import './Navbar.css';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import ProgressBar from './ProgressBar';
import { blue } from '@mui/material/colors';



function About() {

    const location = useLocation();


    return (
        <div className="about-container">
            <div id="about-test">
                <div id="about"></div>
            </div>
            <div className='about-card'>

                <div className='resume-skill-col'>

                    <div className='skills'>
                        <h2>Skills</h2>
                        <p className='skill-name'>JavaScript</p>
                        <ProgressBar bgcolor={"blue"} completed={95}></ProgressBar>
                        <p className='skill-name'>Python</p>
                        <ProgressBar bgcolor={"blue"} completed={85}></ProgressBar>
                        <p className='skill-name'>NodeJs</p>
                        <ProgressBar bgcolor={"blue"} completed={85}></ProgressBar>
                        <p className='skill-name'>ReactJs</p>
                        <ProgressBar bgcolor={"blue"} completed={95}></ProgressBar>
                        <p className='skill-name'>HTML5/CSS3</p>
                        <ProgressBar bgcolor={"blue"} completed={90}></ProgressBar>
                        <p className='skill-name'>SQLAlchemy/Postgresql</p>
                        <ProgressBar bgcolor={"blue"} completed={95}></ProgressBar>
                        <p className='skill-name'>Java</p>
                        <ProgressBar bgcolor={"blue"} completed={85}></ProgressBar>
                        <p className='skill-name'>MongoDB</p>
                        <ProgressBar bgcolor={"blue"} completed={90}></ProgressBar>
                        <p className='skill-name'>Spring Boot</p>
                        <ProgressBar bgcolor={"blue"} completed={80}></ProgressBar>
                    </div>
                </div>
                <div className='about-me-col'>
                    <h2>About Me</h2>
                    <p className='about-me-text'>
                        Full-stack developer with a knack for data structures and algorithms, and
                        experience with a wide range of languages and frameworks. Diverse background
                        in entrepreneurship, sales, and business analysis. Curious problem-solver
                        who strives for improvement every day. View my projects, and let's talk!
                    </p>
                    <Button variant="contained" href="https://drive.google.com/file/d/1zLS8JBf7X6U6yKm-HfpeHeh_wGDfRbfS/view?usp=sharing">My Resume</Button>
                </div>



            </div>
        </div>
    );
}

export default About;